import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';

import AnimateFormatted from '../AnimateFormatted';
import FadeUpIntoView from '../FadeUpIntoView';
import MegaTitle from '../MegaTitle';

const TextImageSplit = ({
  mainTitle,
  title,
  text,
  image,
  theme,
  titleDelay,
}) => {
  return (
    <section
      className={classnames('panel', 'panel--inset', 'text-image-split', {
        [`text-image-split--${theme}`]: theme,
      })}
    >
      <MegaTitle
        title={mainTitle}
        extraClass="text-image-split__mega-title"
        leftMobile={true}
        theme="light"
        skip={titleDelay}
      />
      <div className="text-image-split__panels">
        <div className="text-image-split__panel text-image-split__panel--content">
          <h2 className="text-image-split__title">
            <AnimateFormatted on="view" text={title} />
          </h2>
          <FadeUpIntoView delayBy={4}>
            <ReactMarkdown className="text-image-split__text" source={text} />
          </FadeUpIntoView>
        </div>

        <div className="text-image-split__panel text-image-split__panel--image">
          <FadeUpIntoView>
            <figure className="text-split__image-wrap">
              <Img
                fluid={image.fluid}
                fadeIn={false}
                loading="eager"
                alt={image.alt}
                className="text-split__image"
              />
            </figure>
          </FadeUpIntoView>
        </div>
      </div>
    </section>
  );
};

export default TextImageSplit;
