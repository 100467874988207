import React, { memo } from 'react';
import Img from 'gatsby-image/withIEPolyfill';

import FadeUpIntoView from '../FadeUpIntoView';

import W from '../../assets/images/site/icon_w.svg';

const PeopleGrid = ({ faces, finale }) => (
  <section className="panel panel--inset people-grid">
    <ol className="people-grid__grid">
      {faces.map(({ fluid, alt }, i) => (
        <li
          className="people-grid__item  people-grid__item--image"
          key={`peopleGrid-${i}`}
        >
          <FadeUpIntoView extraClass="people-grid__image-wrap">
            <Img
              fluid={fluid}
              fadeIn={false}
              loading="eager"
              alt={`The People: ${alt}`}
              className="people-grid__image"
            />
          </FadeUpIntoView>
        </li>
      ))}
      <FadeUpIntoView
        as="li"
        extraClass="people-grid__item people-grid__item--text"
      >
        <span>{finale}</span>
        <W className="people-grid__w" />
      </FadeUpIntoView>
    </ol>
  </section>
);

export default memo(PeopleGrid);
