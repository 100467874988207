import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import AnimateFormatted from '../components/AnimateFormatted';
import FadeUpOnLoad from '../components/FadeUpOnLoad';
import FadeUpIntoView from '../components/FadeUpIntoView';
import FullBleedImage from '../components/FullBleedImage';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import SplitTextPanels from '../components/SplitTextPanels';
import IconGrid from '../components/IconGrid';
import TextImageSplit from '../components/TextImageSplit';
import TextSplit from '../components/TextSplit';
import PeopleGrid from '../components/PeopleGrid';

const People = () => {
  const data = useStaticQuery(graphql`
    query PeoplePageQuery {
      content: contentfulPeoplePage {
        metaTitle
        metaDescription
        heroTitle
        heroLeadParagraph {
          heroLeadParagraph
        }
        heroParagraph {
          heroParagraph
        }
        peopleFaces {
          fluid(maxWidth: 600, quality: 95) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          alt: title
        }
        peopleGridFinale
        splitTextImageMainTitle
        splitTextImageTitle
        splitTextImageText {
          splitTextImageText
        }
        splitTextImageImage {
          alt: description
          fluid(maxWidth: 1400, quality: 95) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        splitPanelTitle
        splitPanelText {
          splitPanelText
        }
        splitPanelImage {
          alt: description
          fluid(maxWidth: 1400, quality: 95) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        textGridTitle
        textGridSubtitle
        textGridIcons {
          title
          icon {
            file {
              src: url
            }
          }
        }
        footerPromo
        footerPromoTheme
        fullBleedImage {
          alt: description

          fluid(maxWidth: 2310, quality: 95) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
        splitTextPanelsTitle
        splitTextPanelsLeftTitle
        splitTextPanelsLeftSubtitle
        splitTextPanelsLeftParagraph {
          splitTextPanelsLeftParagraph
        }
        splitTextPanelsRightTitle
        splitTextPanelsRightSubtitle
        splitTextPanelsRightParagraph {
          splitTextPanelsRightParagraph
        }
      }
    }
  `);

  const {
    content: {
      metaTitle,
      metaDescription,
      heroTitle,
      heroLeadParagraph: { heroLeadParagraph },
      heroParagraph: { heroParagraph },
      peopleFaces: faces,
      peopleGridFinale: finale,
      splitTextImageMainTitle,
      splitTextImageTitle,
      splitTextImageText: { splitTextImageText },
      splitTextImageImage,
      splitPanelTitle,
      splitPanelText: { splitPanelText },
      splitPanelImage,
      textGridTitle,
      textGridSubtitle,
      textGridIcons,
      fullBleedImage,
      splitTextPanelsTitle,
      splitTextPanelsLeftTitle,
      splitTextPanelsLeftSubtitle,
      splitTextPanelsLeftParagraph: { splitTextPanelsLeftParagraph },
      splitTextPanelsRightTitle,
      splitTextPanelsRightSubtitle,
      splitTextPanelsRightParagraph: { splitTextPanelsRightParagraph },
      footerPromo,
      footerPromoTheme,
    },
  } = data;

  return (
    <Layout
      headerTheme="dark"
      footerPromo={footerPromo}
      footerPromoTheme={footerPromoTheme}
    >
      <SEO
        title={metaTitle}
        description={metaDescription}
        themeColor="rgb(255, 255, 255)"
      />
      <section className="panel panel--white lead-split-panel lead-split-panel--grey lead-split-panel--people">
        <div className="lead-split-panel__inner">
          <h1 className="lead-split-panel__title">
            <AnimateFormatted on="load" text={heroTitle} />
          </h1>
          <FadeUpIntoView>
            <p className="lead-split-panel__lead-text">{heroLeadParagraph}</p>
          </FadeUpIntoView>
          <FadeUpOnLoad delayBy={2}>
            <ReactMarkdown
              className="lead-split-panel__text"
              source={heroParagraph}
            />
          </FadeUpOnLoad>
        </div>
      </section>
      <PeopleGrid {...{ faces, finale }} />
      <TextImageSplit
        mainTitle={splitTextImageMainTitle}
        title={splitTextImageTitle}
        text={splitTextImageText}
        image={splitTextImageImage}
      />
      <TextSplit
        title={splitPanelTitle}
        text={splitPanelText}
        image={splitPanelImage}
        linkAlign="left"
        flipped={true}
        linkText="Our services"
        link="/services"
        reducedLinkSpace={true}
      />
      <IconGrid
        title={textGridTitle}
        subtitle={textGridSubtitle}
        items={textGridIcons}
        theme="light"
        reduced={true}
      />
      <FullBleedImage fluid={fullBleedImage.fluid} alt={fullBleedImage.alt} />
      <SplitTextPanels
        title={splitTextPanelsTitle}
        leftPanel={{
          title: splitTextPanelsLeftTitle,
          subtitle: splitTextPanelsLeftSubtitle,
          text: splitTextPanelsLeftParagraph,
        }}
        rightPanel={{
          title: splitTextPanelsRightTitle,
          subtitle: splitTextPanelsRightSubtitle,
          text: splitTextPanelsRightParagraph,
        }}
      />
    </Layout>
  );
};

export default People;
